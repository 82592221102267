var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Dx5pT9LC_DtHopOpH2YWx"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

// ignored extension errors
const sentryOptions = {
  ignoreErrors: [
    // Unsaved changes modal error
    'Route change aborted - unsaved changes',
    /**
     * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
     * This can also be filtered by enabling the browser extension inbound filter
     */
    "TypeError: can't access dead object",
    /**
     * React internal error thrown when something outside react modifies the DOM
     * This is usually because of a browser extension or Chrome's built-in translate
     */
    "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    "Failed to execute 'writeText' on 'Clipboard': Write permission denied.",
  ],
  allowUrls: [/^https?:\/\/client\.cdn77\.com/],
};

Sentry.init({
  ...sentryOptions,
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.2,
  environment: process.env.NEXT_PUBLIC_STAGING === 'true' ? 'Staging' : 'Production',
  release: process.env.npm_package_version,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.2,
  integrations: [
    // Sentry.thirdPartyErrorFilterIntegration({
    //   filterKeys: ['cdn77-js-app'],
    //   behaviour: 'drop-error-if-contains-third-party-frames',
    // }),
    new Sentry.Replay(),
  ],
});
